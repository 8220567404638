import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createDeviceDetector } from "next-vue-device-detector";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

const app = createApp(App);
export const device = createDeviceDetector();

router.beforeEach((to, next) => {
    document.title = `${ process.env.VUE_APP_TITLE } - ${to.meta.title}`
})

require('@/config/axios');

createApp(App).use(device).use(router).mount('#app')
