import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
      path: '/',
      name: 'layout',
      component: () => import('@/components/index.vue'),
      meta: {
          title: 'Trang Chủ',
      },
      children: [
          {
              path: 'detail/:id',
              name: 'detail',
              component: () => import('@/views/detail.vue'),
              meta: {
                  title: 'Chi tiết',
              }
          },
          // {
          //     path: 'maps',
          //     name: 'maps',
          //     component: () => import('@/views/maps/index.vue'),
          //     meta: {
          //         title: 'Bản Đồ',
          //     }
          // },
          // {
          //     path: 'info',
          //     name: 'info',
          //     component: () => import('@/views/info.vue'),
          //     meta: {
          //         title: 'Thông tin dự án',
          //     }
          // }
      ]
  },
]

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes
})

export default router
